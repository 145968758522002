import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import "../home/home.css";
import HeaderBar1 from '../header/header1';
import FooterBar from '../footer/footer';
import CardPage from '../home/card';
import { css } from "@emotion/core";
import silde1 from '../header/assets/images/slider-1.jpg';
import silde2 from '../header/assets/images/slider-2.jpg';
import silde3 from '../header/assets/images/slider-3.jpg';
import silde4 from '../header/assets/images/slider-4.jpg';
import silde5 from '../header/assets/images/slider-5.jpg';
import silde6 from '../header/assets/images/slider-6.jpg';
import silde7 from '../header/assets/images/slider-7.jpg';
import silde8 from '../header/assets/images/slider-8.jpg';
import silde9 from '../header/assets/images/slider-9.jpg';
import silde10 from '../header/assets/images/slider-10.jpg';
import silde11 from '../header/assets/images/slider-11.jpg';
import silde12 from '../header/assets/images/slider-12.jpg';
import silde13 from '../header/assets/images/slider-13.jpg';
import silde14 from '../header/assets/images/slider-14.jpg';
import silde15 from '../header/assets/images/slider-15.jpg';

class HomePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

  
  }
  override=()=>{

  return css`
  display: block;
  margin: 0 auto;
  border-color: red;
`};

  render () {
    return (
      <div>
          {/* < BlockLoading/> */}
      <HeaderBar1/>      

      <div id="home" className="slider-area" style={{textTransform:"uppercase"}}>
    <div className="bd-example">
      <div id="carouselOne" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselOne" data-slide-to={0}  className="active"/>
          <li data-target="#carouselOne" data-slide-to={1} />
          <li data-target="#carouselOne" data-slide-to={2} />
          <li data-target="#carouselOne" data-slide-to={3} />
          <li data-target="#carouselOne" data-slide-to={4} />
          <li data-target="#carouselOne" data-slide-to={5} />
          <li data-target="#carouselOne" data-slide-to={6} />
          <li data-target="#carouselOne" data-slide-to={7} />
          <li data-target="#carouselOne" data-slide-to={8} />
          <li data-target="#carouselOne" data-slide-to={9} />
          <li data-target="#carouselOne" data-slide-to={10} />
          <li data-target="#carouselOne" data-slide-to={11} />
          <li data-target="#carouselOne" data-slide-to={12} />
          <li data-target="#carouselOne" data-slide-to={13} />
          <li data-target="#carouselOne" data-slide-to={14} />
        </ol>
        <div className="carousel-inner">
       
          <div className="carousel-item bg_cover active blur " style={{
            backgroundImage: `url(${silde1})`

            }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Software & CRM Consulting Services</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="" >LAND YOUR</a></li>
                      <li><a className="main-btn rounded-one  line button-color" href=""><span className="button-font">DREAM JOB</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde2})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Web Development</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">REDEFINE </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"><span  className="button-font">INTERNET</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde3})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Big Data/Data Science</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#"> DATA INTO</a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">INSIGHTS</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde4})`,
 
            
          }}>
            <div className="carousel-caption" >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title removeblur"><span > Artificial Intelligence/Machine Learning/Cloud</span></h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#" > DIGITAL BECOMES</a></li>
                      <li><a className="main-btn rounded-one line  button-color" href="#"  
> <span  className="button-font">HUMAN</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

          <div className="carousel-item bg_cover blur" style={{
            //  backgroundColor:"#000",
            //  opacity:"0.5",
            backgroundImage: `url(${silde5})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Mobile and Desktop Applications</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">WE DEVELOP</a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#">  <span  className="button-font">FUTURE</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde6})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title" style={{textTransform:"none"}}>DevOps AND SITE RELIABILITY ENGINEER</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">WE DO</a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">EVERYTHING</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

        
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde7})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title" style={{textTransform:"none"}}>INTERNET OF THINGS(IoT)</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">MAKE YOUR </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#" style={{textTransform:"none"}}> <span  className="button-font">OWN THING </span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

        
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde8})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Manual, Automation and Performance Testing</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">BEWARE OF </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">BUGS </span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde9})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Production and Application Support</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">WE DELIVER </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">RIGHT TIME </span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde10})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">SAP</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">RUN </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">SIMPLE</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

        
          
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde11})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Salesforce</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">AUTOMATION </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">APPLIED</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

        
          
              
          
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde12})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Cyber Security</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">THE BUG</a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">STOPS HERE</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}

          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde13})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Computer Networks</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#"> WE BUILD </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"> <span  className="button-font">NETWORKS</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
       
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde14})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Telecommunication</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#"> STAY</a></li>
                      <li><a className="main-btn rounded-one line  button-color" href="#"><span  className="button-font">CONNECTED</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
       
          <div className="carousel-item bg_cover blur" style={{
            backgroundImage: `url(${silde15})`
          }}>
            <div className="carousel-caption">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-7 col-sm-10">
                    <h2 className="carousel-title">Leadership Team</h2>
                    <ul className="carousel-btn rounded-buttons">
                      <li><a className="main-btn rounded-three line" href="#">MAKE IT </a></li>
                      <li><a className="main-btn rounded-one line button-color" href="#"><span  className="button-font">BETTER</span></a></li>
                    </ul>
                  </div>
                </div> {/* row */}
              </div> {/* container */}
            </div> {/* carousel caption */}
          </div> {/* carousel-item */}
       
        </div> {/* carousel-inner */}
        <a className="carousel-control-prev" href="#carouselOne" role="button" data-slide="prev">
          <i className="lni-arrow-left-circle" />
        </a>
        <a className="carousel-control-next" href="#carouselOne" role="button" data-slide="next">
          <i className="lni-arrow-right-circle" />
        </a>
      </div> {/* carousel */}
    </div> {/* bd-example */}
  </div>

<CardPage/>               
<FooterBar/>
</div>
                    
    )
  }
}

export default HomePage;
