 import React, { Component } from 'react'
 import 'bootstrap/dist/css/bootstrap.min.css'
 import '../home/card.css';
 
 import clouds from '../../assets/images/weather.svg'
 import cyber from '../../assets/images/cyber-security.svg'
 import devops from '../../assets/images/devops.svg'
 import test from '../../assets/images/test.svg'
 import iot from '../../assets/images/iot.svg'
 import products from '../../assets/images/products.svg'
 import sap from '../../assets/images/sap.svg'
 import sales from '../../assets/images/sales.svg'
 import network from '../../assets/images/network.svg'
 import tele from '../../assets/images/tele.svg'
 import lead from '../../assets/images/lead.svg'
 import ux from '../../assets/images/ux.svg' 
 
class CardPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      }
  }

  render () {
    return (
      <div>
             
<section className=" style-two" style={{paddingBottom: '60px'}}>
  <div className="auto-container">
    {/*Sec Title*/}
    <div className="sec-title centered">
    
      <h2 style={{fontWeight: "bolder",fontSize: "1.6rem",color: "darkcyan",padding:"2%"}}>OUR SERVICES</h2>
    </div>
    <div className="row clearfix">  
         {/*Services Block*/}
         <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">SAP</div>
          <div className="icon-box">
            <span className>
            
            <img  src={sap} style={{width:"85px"}} alt="fireSpot"/>

    </span>
          </div>
          <h2><span   className="capitalizet">SAP</span></h2>
          <div className="text family2"> 
As we all know SAP is the market leader in enterprise application software, helping companies of all sizes and in all industries run at their best: 77% of the world’s transaction revenue touches an SAP system. Our machine learning, Internet of Things (IoT), and advanced analytics technologies help turn customers’ businesses into intelligent enterprises. Our business partner has 25 years of strong experience in SAP modules. We have 10000+ SAP professionals are available to help start-ups to 500 clients. Explore our curated resource lists on multiple modules like SAP FIORI/UI5, S4 HANA, SAOP ECC, ABAP, BASIS Admin, PP, MM, WM, FICO, HCM/HR (Time, Payroll, ESS, MSS, OM,PA), SuccessFactors,  PM, SD,PS, NetWeaver, QM, CRM, FPSL           </div>
        </div>
      </div>
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">sales</div>
          <div className="icon-box">
            <span className><img src={sales} alt="virtual reality" width="85px" /></span>
          </div>
          <h2> <span   className="capitalizet">Salesforce</span></h2>
          <div className="text family2">
          Our Salesforce team helps marketing, sales, commerce, Platform & Ecosystem, Integration, Analytics service and IT teams collaborate more effectively, even when individuals are working remotely during this COVID situation. With the Salesforce cloud-computing model, our Salesforce Consultant can customise and scaled quickly to the meet needs of small, medium and enterprise-sized businesses as they grow. We have multiple resources diligently working with sales cloud, Service cloud, Marketing Cloud, Lightning Component Framework, Apex, Visualforce, Salesforce.com, web services, IDE, with the below certifications:<br></br>
    1. Force.com Developer (DEV-401, DEV-501, DEV-502, ADM-201)<br></br>
    2. App Builder<br></br>
    3. Salesforce Certified Commerce Cloud Digital Developer<br></br>
    4. Platform Developer I<br></br>
    5. Platform Developer II <br></br>
    6. Salesforce Certified Administrator<br></br>
    7. Salesforce Technical Architect certification (CTA)             </div>
        </div>
      </div>       
    
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter"><span>IoT</span></div>
          <div className="icon-box">
            <span className>
            <img  src={iot} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          <h2>  <span   className="capitalizet">Internet Of Things (IoT) </span></h2>
          <div className="text family2">
          IoT taking all the things in the world and connecting them to the internet it uses to collect and send Information, Receive and act on Information and our consultants are expert in both. We are utilizing the latest ultra-low power embedded devices connected to the internet. Our resources are specialized in UI-centric Approach, Big Data, Node.js Development, GPS systems, Security, JavaScript, Python, API Automation, IT security. Our employees have prior experience in working with Amazon Alexa, Google Home, Netflix, Smartwatches, IoT Based Weather Reporting System, Liquid Level Monitoring System, Air Pollution Monitoring System, Health Monitoring System, Traffic Management System, Smart Anti-theft System              </div>
        </div>
      </div>
    
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">WEB</div>
          <div className="icon-box">
            <span className>
            
            <img  src={ux} style={{width:"85px"}} alt="fireSpot"/>

    </span>
          </div>
          {/*<div class="title">Digital Agency</div>*/}
          <h2 >
         <span  className="capitalizet" > Web Development</span>
            </h2>
          <div className="text family2"> 
          
          
          We help start-ups to Fortune 500 companies drive profits and succeed through cutting edge digital transformation with end-to-end mobile, web, and cloud app development. We have personally hired 1000+ developers across the globe. Our aim is to help our clients to create a transformative impact for businesses with agile work practices across a broad range of technologies including Core Java, J2EE, JSP, Servlets,  Stack, Ruby, PHP, AngularJS, Angular 6,7,8, .NET, HTML5, CSS3, .Net Core, NodeJS, React Native, React JS, C++, C#, SQL Server(SSRS, SSAS, SSIS), ETL, ASP, Mainframe, Swift, Objective C, Kotlin, NoSQL, API Services(SOAP & RESTFUL)          </div>
        </div>
      </div>
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">CYBER</div>
          <div className="icon-box">
            <span className><img src={cyber} alt="virtual reality" width="85px" /></span>
          </div>
          {/*<div class="title">Digital Agency</div>*/}
          <h2  >  <span   className="capitalizet">Cyber Security</span></h2>
          <div className="text family2">
          A lack of focus on security can damage your business in range of ways including: Economic costs, Reputational costs, Regulatory costs, protect all information assets, whether in hard copy or digital form/computer/ data’s. We've compiled a comprehensive list of resources for small-midsized businesses. Our security Consultants are expertise in Network security, Endpoint security, Application security, Cloud security, Server security, Data security, Firewall/IDS/IPS, Malware Prevention, Risk Management, Threat Intelligence are experts in with certifications in CEH (Certified Ethical Hacker)
OSCP, CISA, GCIH, CISSP, CISSP-ISSAP, CISSP-ISSEP, CISSP-ISSMP.              </div>
        </div>
      </div> 



      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">DevOps</div>
          <div className="icon-box">
            <span className>
              <img  src={devops} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          <h2 >   <span   className="capitalizet">DevOps And Site Reliability</span></h2>
          <div className="text family2">
            
          Our DevOps professional expert in the principles of continuous development and deployment, automation of configuration management, inter-team collaboration and IT service agility using DevOps tools. According to the Previous survey, the implementation rate has increased significantly. Our DevOps professional can help our customers to Reduce Implementation Failure, Reflections and Recovery Time, Reduce Costs and IT Staff. They are expertise in Git/GitHub, CI/CD, Docker, Jenkins, Bamboo, Kubernetes, vagrant Chef, Puppet, Ansible 2.0, Nagios, Zabbix, Splunk, Maven, Junit, TeamCity, Saltstack, CVS, Subversion                </div>
        </div>
      </div> 
  {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '0ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">TESTING</div>
          <div className="icon-box">
            <span className="">
            
            <img  src={test} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          {/*<div class="title">Service Provide</div>*/}
          <h2><span   className="capitalizet">Testing </span></h2>
          <div className="text family2">
          Our Testers advocating the end user and making sure that they are being delivered a quality product. Without someone to find bugs before software is delivered, many businesses would be suffering from poor reputations and unloyal customer bases, But here our experts are available all over the world to find the bugs and fix the problems on time without fail. They are expertise in doing manual, Automation and performance testing (DevOps & Agile Methodology, SOAP UI tool, Web & Mobile Technologies, Programming, Testing Tools & Techniques). We have below skilled resources available for your needs at any given time – HP QC, HP QTP, ETL Testing, TOAD, Mercury QC, CI/CD, HP QC-ALM, UAT, Selenium IDE, Selenium, Appium, MicroFocus, Katalon Studio, Jasmine, SOAtest, JMeter, Tosca, RFT, Telerik, Cucumber, WebLoad, LoadNinja, LoadView, Smartmeter.io, NeoLoad, LoadRunner
              </div>
        </div>
      </div>
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">Support</div>
          <div className="icon-box">
            <span className>
            
            <img  src={products} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          
          <h2><span   className="capitalizet">Production & Application Support</span></h2>
          <div className="text family2">
          Our production support team is responsible for monitoring the production servers, scheduled jobs, incident management and receiving incidents and requests from end-users, analyzing these and either responding to the end user with a solution or escalating it to the other IT teams, Perform proactive maintenance activities, engage in automation activities, and perform root cause analysis and remediation, Performance tuning. Our support guys skills in UNIX/Linux, Servers, SQL Development,  Shell Scripting, Autosys, Control-M, Patch Management, Helpdesk ServiceDesk, Back panel, encryption technologies PL/SQL, L1, L2, L3, with 24/7 support with good programming experience. They are good at Middleware component including JBOSS, Oracle WebLogic, IBM WebSphere, Incident and problem management          
                 </div>
        </div>
      </div>
   

        {/*Services Block*/}
        <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">Network</div>
          <div className="icon-box">
            <span className>
            
            <img  src={network} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          
          <h2> <span   className="capitalizet">Computer Networks</span></h2>
          <div className="text family2">
          Networking is crucial for any modern enterprise to function, enterprise networks must be highly available, scalable, and robust. Our IT professional skilled in multiple areas of networking will always be on top of the list and in high demand. We have huge number of networking professionals available for your needs with CCNA, CCNP, CCIE, CCDA, CCDP, CCDE, RedHat certifications. Our resources are specialized in Cisco routers, Switches, LAN,  WAN, MAN, WLAN, DNS,  PAN, DAN, VoIP, Cloud deployment, Data Migration, MPLS, Access points, TCP/IP, Firewall, Active Directory, F5, OSPF, BGP, OSI Layers, DCP, DHCP, Aruba, Automation Scripting, Juniper        
                      </div>
        </div>
      </div>
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">Tele</div>
          <div className="icon-box">
            <span className>
            
            <img  src={tele} style={{width:"85px"}} alt="fireSpot"/>

    </span>
          </div>
          <h2 ><span   className="capitalizet">Telecommunication</span></h2>
          <div className="text family2">
            
          Our telecom professional has expertise in various types of technologies over wire, radio, optical or other electromagnetic systems. Most of our customer's data that we use are stored in the cloud automatically using cloud computing skills. Our experts working on 5G, UMTS, LTE 4G, Antenna, Wireless Networking, VoIP Systems, GSM, RF, Working on upcoming 6G Superfast Wi-Fi 6/802.11ax, Satellite, and designing and overseeing the installation of telecommunications equipment and facilities, such as complex electronic switching systems, copper wire telephone facilities, fiber optics cabling / internet protocol data systems with the below mentioned certifications.
CTA, CVA, CWA, CIPTS, CTSME, CTNS, IPEP, RCDD, iNARTE                  </div>
        </div>
      </div>
      {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">Lead</div>
          <div className="icon-box">
            <span className><img src={lead} alt="virtual reality" width="85px" /></span>
          </div>
          <h2 ><span   className="capitalizet">Leadership Team</span></h2>
          <div className="text family2">
          Our leader sets a clear vision for the organization, motivates employees, guides employees through the work process and builds morale. Our Leadership teams involves leading others toward achieving clear goals. We have below resources for your need. <br></br>
    1) CE0, CFO, CTO, CIO, <br></br>
    2) Director of Software Delivery, BigData, AI, Cloud Services. <br></br>
    3) Vice President, Software Development Manager<br></br>
    4) Information Security Manager, Sr Project/Program/Product Manager, Scrum Master
    
                 </div>
        </div>
      </div>  

         {/*Services Block*/}
      <div className="services-block col-md-4 col-sm-6 col-xs-12">
        <div className="inner-box wow fadeIn animated" data-wow-delay="500ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '500ms', animationName: 'fadeIn'}}>
          <div className="left-layer" />
          <div className="right-layer" />
          <div className="big-letter">Clouds</div>
          <div className="icon-box">
            <span className>
            
            <img  src={clouds} style={{width:"85px"}} alt="fireSpot"/>

              </span>
          </div>
          {/*<div class="title">Digital Agency</div>*/}
          <h2> <span   className="capitalizet">AI, ML, Cloud</span></h2>
          <div className="text family2" > 
          AI can solve problems as a human would. Our AI Consultants are expertise in machine learning, deep learning, robotics, image recognition, natural language processing, sentiment analytics, recommendation engines. We have placed 1000+ Data engineering, cloud consultant across the global. They are technical background in computer science and software engineering with expertise in high-performance computing, big data, algorithms, databases (SQL, Oracle, Sybase, NoSQL, MongoDB, Hadoop, HANA), and distributed systems. 
We have plenty of resources with Analytical Skills, Data Visualization, Data Mining, Data Analytics, Data Lakes, Blockchain(Solidity, Ethereum, S3), Private & public clouds, SaaS, PaaS, IaaS, in-house cloud, BI, SAS, Scala, Spark, AWS S3, AWS EC2, Docker, Python libraries (Django, R, Pandas, NumPy, Keras, Scikit-learn, PyTorch, Seaborn), RPA(UiPath, Blueprism, Automation Anywhere, Pega), HDFS, MapReduce, YARN, Hadoop, Cassandra, Cloudera and TensorFlow
          </div>
        </div>
      </div>
         
    
    </div>
    <br />
   </div>
</section>
</div>
                    
    )
  }
}

export default CardPage;
