import React, { Component } from 'react'
 import 'bootstrap/dist/css/bootstrap.min.css'
 import { store } from 'react-notifications-component'

import "../home/home.css";
import HeaderBar1 from '../header/header1';
import FooterBar from '../footer/footer';
import '../../components/about/about.css';

import { css } from "@emotion/core";

import '../about/sideassets/assets/css/style.css'
import '../about/assets/css/style.css';
import '../about/aboutassets/assets/css/style.css'


import '../contact/css/main.css';
import '../contact/css/util.css';

import Formimg from '../contact/images/img-01.png';
import profile from '../contact/images/user.svg';
import user from '../contact/images/email.svg';

import '../contact/vendor/animate/animate.css';
import '../contact/vendor/select2/select2.min.css';
import '../contact/vendor/css-hamburgers/hamburgers.min.css'
import '../contact/form.css';
import 'react-notifications-component/dist/theme.css'
import 'animate.css'
import ReactNotifications from 'react-notifications-component'
class FormPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
    loading: true,

data:{ 
  name:'',
  
  email:'',
  message:'',

}
    
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
}

formDatas=(event)=>{
 
event.preventDefault()

if(this.state.data.name!==''&&this.state.data.email!==''&&this.state.data.message!==''){


  var age=this.state.data.name;
  var emailId=this.state.data.email;
  var sms=this.state.data.message;
  window.Email.send({
    SecureToken: '6c7b8d0b-e328-416f-8e85-53fd07fd836e',

    To : 'reqsta@reqsta.com',
    From : "mern28671@gmail.com",
    Subject :"REQSTA CLIENTS QUERIES",
    Body:'Name='+'\r\n'+(age + "\n" +",")+'\r\n'+'Email='+'\r\n'+(emailId + "\n" +",")+'\r\n'+'Message='+'\r\n'+(sms + "\n" +"."),
  

    
   
   
    
    Attachments : [
      {
        name : "reqsta.png",
      
        path : "https://media-exp1.licdn.com/dms/image/C560BAQFT2MKxBk5Eog/company-logo_200_200/0/1587910194165?e=2159024400&v=beta&t=Ge2J4ANg4PXCOg6tALdhqIw5B8cEG-15-vZiH4OHXAI"
      }]
 

 }).then(
message => 


  store.addNotification({
    title: 'Success!',
    message: 'Thanks For Visiting',
    type: 'success',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 1000
      // onScreen: true
    
    
    },
     
    onRemoval: () => {
    }

  },
  this.clratdata()

  )

);



}
else{
  store.addNotification({
    title:"warning",
    message: 'Enter All Details',
    type: "warning",
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 1000
      // onScreen: true
    },
    onRemoval: () => {
    }
  })
}
}
  override=()=>{

  return css`
  display: block;
  margin: 0 auto;
  border-color: red;
`};

clratdata=()=>{
  



 
    document.getElementById("myForm").reset();

  


}
  render () {
    return (
      <div>
         
      <HeaderBar1/>      

<div>

  {/* ======= Hero Section ======= */}
  <section id="hero2" className="d-flex justify-content-center align-items-center" >
  <ReactNotifications />
    <div className="container position-relative" data-aos="zoom-in" data-aos-delay={100}>
      <h1 >
      Need A Hand ? Or High Five ?<br></br>
Here How To Reach Us
      </h1>
      <h2 style={{fontFamily:"fantasy",visibility:"hidden"}}>

      Our Vision is to Empower people to grow personally,<br></br>
       professionally and financially so that they can <span style={{color:"lightgrey",fontFamily:"revert",fontWeight:"600"}}>“Be the Star”</span><br></br> to the world around them.<br></br>
     
      Reqsta is a premier technology solutions firm helping,<br></br> Organizations expedite business growth<br></br> 
          </h2>
      <a href="#" style={{textDecoration:"none"}} className="btn-get-started">CONTACT US</a>
    </div>
  </section>{/* End Hero */}
  <div className="bg-contact100" style={{backgroundImage: 'url("images/bg-01.jpg")'}}>
 
  <div className="container-contact100">
    <div className="wrap-contact100">
      <div className="contact100-pic js-tilt hover01 column" data-tilt>
      <figure> <img src={Formimg} alt="IMG" /></figure>
      </div>
      <form className="contact100-form validate-form" id="myForm">
        <span className="contact100-form-title">
          Get in touch
        </span>
        <div className="wrap-input100 validate-input" data-validate="Name is required">
          <input className="input100" type="text" name="name" placeholder="Name" required value={this.state.name} 
          
          onChange={(e) => this.setState({ data: {...this.state.data,name:e.target.value} })}
          />
          <span className="focus-input100" />
          {/* profile */}
          <span className="symbol-input100">  <img src={profile} alt="IMG" style={{height:"15px",width:"15px"}}/>{''}
            {/* <i className="fa fa-user" aria-hidden="true" /> */}
          </span>
        </div>
        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
          <input className="input100" type="text" name="email" placeholder="Email"
          
          onChange={(e) => this.setState({ data: {...this.state.data,email:e.target.value} })}
          required/>
          <span className="focus-input100" />
          <span className="symbol-input100">
          <img src={user} alt="IMG" style={{height:"15px",width:"15px"}}/>{''}          </span>
        </div>

        <div className="wrap-input100 validate-input" data-validate="Message is required">
          <textarea 
          className="input100"
           name="message"
            placeholder="Message"
             defaultValue={""} 
           onChange={(e) => this.setState({ data: {...this.state.data,message:e.target.value} })}

           required/>
          

          <span className="focus-input100" />
        </div>
        <div className="container-contact100-form-btn">
          <button className="contact100-form-btn" onClick={this.formDatas}>
            Send
          </button>
         
        </div>
      </form>
    </div>
  </div>
</div>

</div>




  
{/* ======= Contact Section ======= */}
<section id="contact" className="contact section-bg">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2 style={{marginTop: "5%",color: "unset",
    fontSize: "2.5ch"}}>contact</h2>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="info-box mb-4">
          <i className="bx bx-map" />
          <h3>Our Address</h3>
          <p>
257A ,Compass Vale Road #05-515
,Singapore – 541257</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-envelope" />
          <h3>Email Us</h3>
          <p>reqsta@reqsta.com</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-phone-call" />
          <h3>Call Us</h3>
          <p> +65 3138 5106 </p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-envelope" />
          <h3>Email Us</h3>
          <p>reqsta@reqsta.com</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-phone-call" />
          <h3>Call Us</h3>
          <p>+1 678 969 4647</p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="info-box mb-4">
          <i className="bx bx-map" />
          <h3>Our Address</h3>
          <p>
          600 N ,Broad Street, STE 5 #3132
 Middletown, DE – 19709

            </p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-envelope" />
          <h3>Email Us</h3>
          <p>reqsta@reqsta.com</p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="info-box mb-4">
          <i className="bx bx-map" />
          <h3>Our Address</h3>
          <p>
          15, 57A, Vellalore Rd,
Kovai Mahalingapuram,Coimbatore,
TamilNadu-641111

            </p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="info-box  mb-4">
          <i className="bx bx-phone-call" />
          <h3>Call Us</h3>
          <p> +91 95666 46360</p>
        </div>
      </div>
    </div>
  </div></section>



<FooterBar/>
</div>
                    
//     )
//   }
// }
    )
    }
    }

export default FormPage;
