import React, { Component } from 'react'
 import 'bootstrap/dist/css/bootstrap.min.css'

import "../home/home.css";
import HeaderBar1 from '../header/header1';
import FooterBar from '../footer/footer';
import '../../components/about/about.css';

import { css } from "@emotion/core";

import '../about/sideassets/assets/css/style.css'
import '../about/assets/css/style.css';
import '../about/aboutassets/assets/css/style.css'
import people from '../../assets/images/people.jpeg'
import client from '../../assets/images/client.jpeg'
import colla from '../../assets/images/colla.jpeg'
import int from '../../assets/images/int.jpeg'

import inv from '../../assets/images/inv.jpeg'

import pride from '../../assets/images/pride.jpeg'
import para from '../../assets/images/para.jpeg'

import About_Cards from '../about/aboutassets/assets/img/about-img2.jpg'
import About_Cards2 from '../about/aboutassets/assets/img/AboutCards2.jpg'

import correct from '../about/aboutassets/assets/img/correct.svg'

import google from '../about/aboutassets/assets/img/brand/search.svg'
import brand from '../about/aboutassets/assets/img/brand/brand.svg'
import client5 from '../about/aboutassets/assets/img/brand/uber.svg'
import client6 from '../about/aboutassets/assets/img/brand/electronics-arts.svg'

 import fb from '../about/aboutassets/assets/img/brand/facebook.svg'
 import github from '../about/aboutassets/assets/img/brand/github.svg'
 import ibm from '../about/aboutassets/assets/img/brand/ibm.svg'
 import mac from '../about/aboutassets/assets/img/brand/mac-os-logo.svg'

import microsoft from '../about/aboutassets/assets/img/brand/microsoft.svg'
 import netflix from '../about/aboutassets/assets/img/brand/netflix.svg'
 import oneplus from '../about/aboutassets/assets/img/brand/one-plus.svg'
 import photoshop from '../about/aboutassets/assets/img/brand/photoshop.svg'


class aboutPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
    loading: true

    
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
}

  override=()=>{

  return css`
  display: block;
  margin: 0 auto;
  border-color: red;
`};

  render () {
    return (
      <div>
        {/* < BlockLoading/> */}
        <HeaderBar1 />
        {/* mendor template */}
        <div>
          {/* ======= Hero Section ======= */}
          <section
            id="hero"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="container position-relative"
              data-aos="zoom-in"
              data-aos-delay={100}
            >
              <h1>We Make Things Happen</h1>
              <h2 style={{ fontFamily: "Poppins sansSerif",fontWeight: "500" }}>
                Our Vision is to Empower people to grow personally,<br></br>
                professionally and financially so that they can{" "}
                <span
                  style={{
                    color: "lightgrey",
                    fontFamily: "revert",
                    fontWeight: "600",
                  }}
                >
                  “Be the Star”
                </span>
                <br></br> to the world around them.<br></br>
                Reqsta is a premier technology solutions firm helping,<br></br>{" "}
                Organizations expedite business growth<br></br>
              </h2>
              <a
                href="#"
                className="btn-get-started"
                style={{ textDecoration: "none" }}
              >
                Get Started
              </a>
            </div>
          </section>
          {/* End Hero */}
        </div>

        {/* mendor templete end */}

        <main id="main">
          {/* ======= About Section ======= */}
          {/* card start*/}

          <section id="services" className="services">
            <div className="inner-container">
              <div className="section-title">
                <h2>About</h2>
                <p>About Us</p>
              </div>
              <div className="row ">
                <div className="col-md-6 col-lg-3 align-items-stretch mb-5 mb-lg-0 about-container">
                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <div className="icon">
                      {/* <i className="bx bxl-dribbble" /> */}
                      <img
                        src={para}
                        className=""
                        alt=""
                        className="bx bxl-dribbble"
                      />
                    </div>
                    <h4 className="title">
                      <a
                        style={{ color: "rgb(119 136 153)", padding: "2% 0%" }}
                      >
                        WHO ARE WE{" "}
                      </a>
                    </h4>
                    <p
                      className="description"
                      style={{
                        fontSize: "1.6ch",
                        fontFamily: "Poppins",
                      }}
                    >
                      Reqsta is a premier technology solutions firm helping
                      organizations expedite business growth. We provide
                      cutting-edge Software & CRM Consulting Services on a variety of
                      technology platforms to clients ranging from Fortune 500
                      companies and to vibrant start-ups operates from offices
                      in 3 countries. We have 500000 Lakh+ talent pool of world
                      class IT professionals, having presence in the following
                      locations: India, Singapore, Malaysia, Nigeria, Saudi
                      Arabia, Germany, Australia, United States, Egypt. Our core
                      depth technical knowledge coupled with industry experience
                      and our unique Recruitment methodologies enable us to
                      successfully deliver and meet our customer’s expectations.
                      We recognize everyone’s contribution to our success - our
                      staff, our customers and our candidates.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {/* ======= About Section ======= */}
              <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-5 col-md-6">
                      <div
                        className="about-img"
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <img src={About_Cards} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                      <div
                        className="about-content"
                        data-aos="fade-left"
                        data-aos-delay={100}
                      >
                        <h2>WHO ARE WE</h2>
                        {/* <h3 >Odio sed id eos et laboriosam consequatur eos earum soluta.</h3> */}
                        <p>
                          Reqsta is a new category of search firm that leverages
                          the power of relationships, referrals, Modern sourcing
                          methods to find you the best talent.{" "}
                        </p>
                        {/* <p>Aut dolor id. Sint aliquam consequatur ex ex labore. Et quis qui dolor nulla dolores neque. Aspernatur consectetur omnis numquam quaerat. Sed fugiat nisi. Officiis veniam molestiae. Et vel ut quidem alias veritatis repudiandae ut fugit. Est ut eligendi aspernatur nulla voluptates veniam iusto vel quisquam. Fugit ut maxime incidunt accusantium totam repellendus eum error. Et repudiandae eum iste qui et ut ab alias.</p> */}
                        <ul>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />{" "}
                            &nbsp; Our technology transforms thousands of
                            subject matter experts into the world’s most
                            powerful talent network.{" "}
                          </li>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            &nbsp; We vet our jobseekers thoroughly, compiling
                            their relevant experience, their qualifications, and
                            their suitability to your open position.
                          </li>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />{" "}
                            &nbsp;Our experts will take all your requirements
                            and preferences into account during the entire
                            process.
                          </li>

                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            &nbsp; Meaning we will only send the very
                            best-suited candidates your way, making your
                            decisions that much simpler to make.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* End About Section */}

              {/* left */}

              <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-7 col-md-6">
                      <div
                        className="about-content"
                        data-aos="fade-left"
                        data-aos-delay={100}
                      >
                        <h2>WHAT WE DO</h2>
                        <p>
                          We care about making sure you get the right outcome,
                          and our mission is to lead in the creation and
                          delivery of innovative workforce solutions and
                          services that enable our clients to win in the
                          changing world of work.
                        </p>
                        {/* <p>Aut dolor id. Sint aliquam consequatur ex ex labore. Et quis qui dolor nulla dolores neque. Aspernatur consectetur omnis numquam quaerat. Sed fugiat nisi. Officiis veniam molestiae. Et vel ut quidem alias veritatis repudiandae ut fugit. Est ut eligendi aspernatur nulla voluptates veniam iusto vel quisquam. Fugit ut maxime incidunt accusantium totam repellendus eum error. Et repudiandae eum iste qui et ut ab alias.</p> */}
                        <ul>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />{" "}
                            &nbsp; We have been in this industry for over 10
                            years, so we understand what you are trying to
                            achieve.{" "}
                          </li>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            &nbsp; We work across the country and source
                            candidates from every corner of the World.
                          </li>
                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />{" "}
                            &nbsp;Our experts will take all your requirements
                            and preferences into account during the entire
                            process.
                          </li>

                          <li>
                            {" "}
                            <img
                              src={correct}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            &nbsp; Our core values are Innovation, Quality,
                            Reliability, Collaboration, Deliver Results. Here
                            are a few tips we have shared with our employees<br></br>
                            <span style={{visibility:"hidden"}}>rtr</span><br></br>
                            1] Develop a long-term relationship<br></br>
                            2] Determine client needs first <br></br>
                            3] Make employer feel valued.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div
                        className="about-img"
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <img src={About_Cards2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* End About Section */}
            </div>
          </section>

          {/* clients end */}
         
          {/* End About Section */}
        </main>

       

        {/* cardds2 */}
        {/* ======= Services Section ======= */}

        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h3>WE VALUE</h3>
              <p>
                We adapt to the changing dynamics of the web world driving
                digital transformation along multi -channel deliveries for a
                highly personalized and convenient experience cutting across
                industries.
              </p>
            </header>
            <div className="row">
              <div
                className="col-md-6 col-lg-4 wow bounceInUp"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#fceef3" }}>
                    <img src={people} className="img-fluid" alt="" />

                    {/* <i className="ion-ios-analytics-outline" style={{color: '#ff689b'}} /> */}
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      PEOPLE
                    </a>
                  </h4>
                  <p className="description">
                    We recognize that ethics,transparency are the core values
                    that drives our business,gaining people trust.
                  </p>{" "}
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#fff0da" }}>
                    <img src={client} className="img-fluid" alt="" />
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      CLIENTS
                    </a>
                  </h4>
                  <p className="description">
                    We see our customers as invited guests to party.It’s our job
                    to make the customer experience great.{" "}
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#e6fdfc" }}>
                    <img src={colla} className="img-fluid" alt="" />
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      COLLABORATION
                    </a>
                  </h4>
                  <p className="description">
                    There are different perspectives to each and every endeavor
                    and we understand each is equally important.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 wow"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#eafde7" }}>
                    <img src={int} className="img-fluid" alt="" />
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      INTEGRITY
                    </a>
                  </h4>
                  <p className="description">
                    To give real service you must add something which cannot be
                    bought, measured with money,that is integrity.{" "}
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#e1eeff" }}>
                    <img src={inv} className="img-fluid" alt="" />
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      INNOVATION
                    </a>
                  </h4>
                  <p className="description">
                    We’ve understood that true innovation is improvising on
                    <br></br> what we have learnt already.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="box">
                  <div className="icon" style={{ background: "#ecebff" }}>
                    <img src={pride} className="img-fluid" alt="" />
                  </div>
                  <h4 className="title" style={{ padding: "5%" }}>
                    <a href style={{ color: "rgb(119 136 153)" }}>
                      PRIDE
                    </a>
                  </h4>
                  <p className="description">
                    We take pride in our brand value that is trust which has
                    helped in expanding our customer base .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Section */}

        <main id="mainio">
          {/* ======= Features Section ======= */}
          <section id="features" className="features">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Happy Clients</h2>
                <p>Companies we have worked with.</p>
              </div>
              <div className="row" data-aos="zoom-in" data-aos-delay={100}>
                <div className="col-lg-3 col-md-4">
                  <div className="icon-box">
                    <img
                      src={google}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Google</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                  <div className="icon-box">
                    <img
                      src={brand}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Tesla</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                  <div className="icon-box">
                    <img
                      src={client6}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Electronics Arts</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
                  <div className="icon-box">
                    <img
                      src={client5}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Uber</a>
                    </h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={fb}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>FaceBook</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={github}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Github</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={ibm}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>IBM</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={mac}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Apple</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={microsoft}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>MicroSoft</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={netflix}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Netflix</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={oneplus}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>OnePlus</a>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <img
                      src={photoshop}
                      className="img-fluid"
                      alt=""
                      style={{ height: "35px", width: "35px" }}
                    />{" "}
                    <h3 style={{ paddingLeft: "12px" }}>
                      <a href>Adobe</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Features Section */}
        </main>
        {/* End #main */}

        {/* brand logo */}

        {/* brand logo */}
        <FooterBar />
      </div>

      //     )
      //   }
      // }
    );
    }
    }

export default aboutPage;
