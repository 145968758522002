import React, { Component } from 'react'
 import 'bootstrap/dist/css/bootstrap.min.css'

import "../home/home.css";
import HeaderBar1 from '../header/header1';
import FooterBar from '../footer/footer';
import '../../components/about/about.css';

import { css } from "@emotion/core";

import '../about/sideassets/assets/css/style.css'
import '../about/assets/css/style.css';
import '../about/aboutassets/assets/css/style.css'



class ContactPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
    loading: true

    
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
}


  override=()=>{

  return css`
  display: block;
  margin: 0 auto;
  border-color: red;
`};

  render () {
    return (
      <div>
          {/* < BlockLoading/> */}
      <HeaderBar1/>      
{/* mendor template */}
<div>
  {/* ======= Hero Section ======= */}
  <section id="hero2" className="d-flex justify-content-center align-items-center" >
    <div className="container position-relative" data-aos="zoom-in" data-aos-delay={100}>
      <h1>
      WE MAKE THINGS HAPPEN
      </h1>
      <h2 style={{fontFamily:"fantasy"}}>

      Our Vision is to Empower people to grow personally,<br></br>
       professionally and financially so that they can <span style={{color:"lightgrey",fontFamily:"revert",fontWeight:"600"}}>“Be the Star”</span><br></br> to the world around them.<br></br>
     
      Reqsta is a premier technology solutions firm helping,<br></br> Organizations expedite business growth<br></br> 
          </h2>
      <a href="#" className="btn-get-started">Get Started</a>
    </div>
  </section>{/* End Hero */}

</div>




  




<FooterBar/>
</div>
                    
//     )
//   }
// }
    )
    }
    }

export default ContactPage;
