
import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import {  Link } from "react-router-dom";


import '../header/assets/css/magnific-popup.css'
import '../header/assets/css/bootstrap.min.css'
import '../header/assets/css/slick.css'
import '../header/assets/css/slick.css'
import '../header/assets/css/animate.css'
import '../header/assets/css/default.css'
import logo from '../header/assets/images/logo-white.png';

import '../header/assets/css/style.css'
import { Modal} from "react-bootstrap";


class HeaderBar1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billdeleteModal:false,
      serviceActive:true,
      aboutActive:'',
      carrerActive:'',
      contactActive:''

    
    }
  }

 

  componentDidMount () {
   
  }


  aboutActiveStatus=()=>{
    this.setState({
      serviceActive:'',
      aboutActive:true,
    });
  }


  contactActiveStatus=()=>{
    this.setState({
      serviceActive:'',
      aboutActive:'',
      contactActive:true
    });
  }
  render () {
    return (

  
  <div className="container" style={{
    paddingBottom: "0",
    marginBottom: "-25px"}}>
{/*====== NAVBAR PART START ======*/}
<section className="header-area" style={{padding: "0px 0"}}>
  <div className="navbar-area fixed-top">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <nav className="navbar navbar-expand-lg">
          <Link to="/"   className="navbar-brand" href="#">
              <img src={logo} alt="Logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarEight" aria-controls="navbarEight" aria-expanded="false" aria-label="Toggle navigation">
              <span className="toggler-icon" />
              <span className="toggler-icon" />
              <span className="toggler-icon" />
            </button>
            <div className="collapse navbar-collapse sub-menu-bar" id="navbarEight">
              <ul className="navbar-nav ml-auto" >
                

                  <li  className=  { this.state.serviceActive== true?"nav-item active":"nav-item"}  >  

                   
                <Link to="/" className="page-scroll " style={{color:"black",textDecoration:"none"}}>SERVICES</Link>

        </li>

        

       
            


                <li className=  { this.state.aboutActive== true?"nav-item active":"nav-item"}   onClick={this.aboutActiveStatus}                 >
                <Link to="/aboutPage"  onClick={this.aboutActiveStatus}  
               
                
                
                className="page-scroll" style={{color:"black",textDecoration:"none"}}    >ABOUT US</Link>
                </li>
                <li className="nav-item"    onClick={
            (e)=>{ e.preventDefault(); 
            this.setState({billdeleteModal: true})

          }}>
                  <a className="page-scroll" href="#portfolio" style={{color:"black",textDecoration:"none"}}>CAREER  </a>
                </li>
                

              <li className="nav-item"     onClick={
            (e)=>{ e.preventDefault(); 
            window.open('https://extraordinary-sunburst-aa5016.netlify.app/')

          }}>
                  <a className="page-scroll" href="#portfolio" style={{color:"black",textDecoration:"none"}}>PRODUCT  </a>
                </li>
                <li  className=  { this.state.contactActive== true?"nav-item active":"nav-item"}                    onClick={this.contactActiveStatus}  >
                <Link to="/ContactPage" className="page-scroll" style={{color:"black",textDecoration:"none"}}   onClick={this.contactActiveStatus}>CONTACT</Link>
                </li>
              </ul>
            </div>
            <div className="navbar-btn d-none mt-15 d-lg-inline-block">
              <a className="menu-bar" href="#side-menu-right"><i className="lni-menu" /></a>
            </div>
          </nav> {/* navbar */}
        </div>
      </div> {/* row */}
    </div> {/* container */}
  </div> {/* navbar area */}
 







  {this.state.billdeleteModal ? (
              <Modal
                className="modal-small second-modal"
                show={true} onHide={() => {
                  this.setState({ billdeleteModal: false })
                 }}
              >
                <div className="modal-body">
                  <div className="pattern-bg-color for-png2  pb_20 pt_20 pop-img">
                    <h1 className="text-center white-head">Under Maintenance</h1>
                  </div>
                  <div className="pt_20">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12 success-text text-center">
                          {/* <i className="far fa-check-circle" /> */}
                          
                          <p style={{color:"lightgreen",visibility:"hidden"}}> In Progress </p>
                          
                          <button
                            type="button"
                            className='btn btn-danger'
                            onClick={() =>{
                              // this.deleteInvoice()
                              this.setState({billdeleteModal: false})} }
                            className="btn btn-dark mt_10"
                            data-dismiss="modal"
                          >
                            Close
                          </button>	&nbsp;	&nbsp;
                          {/* <button
                            type="button"
                            className='btn btn-danger'
                            onClick={() =>{
                            
                              this.setState({billdeleteModal: false})} }
                            className="btn btn-dark mt_10"
                            data-dismiss="modal"
                          >
                           Stay Connected
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

</section>
{/*====== NAVBAR PART ENDS ======*/}
</div>
)
  }
}

export default HeaderBar1;





      
