import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../footer/footer.css';
import { Link } from 'react-router-dom';

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {}

  componentDidMount() {}

  srcollBar = () => {
    window.scrollTo(0, 800);
  };

  srcollBarTwo = () => {
    window.scrollTo(0, 1400);
  };
  srcollBarThree = () => {
    window.scrollTo(0, 1900);
  };
  srcollBarFour = () => {
    window.scrollTo(0, 2450);
  };
  render() {
    return (
      <footer className="footer-area footer--light">
        <div className="footer-big" style={{ background: 'rgb(25 32 39)' }}>
          {/* start .container */}
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <h4 className="footer-widget-title">SERVICES</h4>
                    <ul>
                      <li>
                        <a onClick={this.srcollBar}>Web Development</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBar}>Cyber Security</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBar}>
                          DevOps And Site Reliability
                        </a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarTwo}>
                          Internet Of Things(IoT)
                        </a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarTwo}>Testing</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarTwo}>
                          Production&Application Support
                        </a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarThree}>SAP</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarThree}>Salesforce</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarThree}>Computer Networks</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarFour}>Telecommunication</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarFour}>Leadership Team</a>
                      </li>
                      <li>
                        <a onClick={this.srcollBarFour}>AI, ML, Cloud</a>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* end /.col-md-4 */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <h4 className="footer-widget-title">INDUSTRIES</h4>
                    <ul>
                      <li>
                        <a href="#">Healthcare</a>
                      </li>
                      <li>
                        <a href="#">Life Sciences</a>
                      </li>
                      <li>
                        <a href="#">Media & Publishing</a>
                      </li>
                      <li>
                        <a href="#">Insurance</a>
                      </li>
                      <li>
                        <a href="#">Finance & Banking</a>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* end /.col-md-3 */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <h4 className="footer-widget-title">CORPORATE OVERVIEW</h4>
                    <ul>
                      <li>
                        <Link to="/aboutPage">
                          {' '}
                          <a href="#">About </a>
                        </Link>
                      </li>
                      <li>
                        <a href="#">Career</a>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* end /.col-lg-3 */}
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <h4 className="footer-widget-title">Help Support</h4>
                    <ul>
                      <li>
                        <Link to="/ContactPage">
                          {' '}
                          <a href="#"> Contact </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* Ends: .col-lg-3 */}
            </div>
            {/* end /.row */}
          </div>
          {/* end /.container */}
        </div>
        {/* end /.footer-big */}
        <div className="mini-footer" style={{ borderTop: '1px solid white' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-text">
                  <p>
                    ReqSta © Copyright {new Date().getFullYear()} | All Rights
                    Reserved.
                  </p>
                </div>
                {/* --------go top icon start--- */}
                {/* <div className="go_top">
            <span className="icon-arrow-up" />
          </div> */}
                {/* --------go top icon start--- */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterBar;
