import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";



import HomePage from '../components/home/home';
import FooterBar from '../components/footer/footer';
import CardPage from '../components/home/card';
import HeaderBar1 from '../components/header/header1';
import aboutPage from '../components/about/about';
import ContactPage from '../components/contact/contact';
import FormPage from '../components/contact/form';
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>
        
       
        <Switch>
          <Route exact path="/Header1" component={HeaderBar1} />
        </Switch>

        <Switch>
          <Route exact path="/Footer" component={FooterBar} />
        </Switch>

        <Switch>
          <Route exact path="/CardPage" component={CardPage} />
        </Switch>
        
 
        <Switch>
          <Route exact path="/aboutPage" component={aboutPage} />
        </Switch>
        
        <Switch>
          <Route exact path="/Contact" component={ContactPage} />
        </Switch>
        <Switch>
          <Route exact path="/ContactPage" component={FormPage} />
        </Switch>

        
      </Router>
    );
  }
}

export default App;
